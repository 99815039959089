<template>
  <div class="page">
    <Header :loginState="loginState" v-if="!isClient"/>
    <div :class="isClient?'account-page-app':'account-page'">
      <left-menu v-if="!isClient"/>
      <div class="account-center">
        <div class="center-box account-information" :class="styleState2">
          <transaction-nav v-if="!isClient"/>
          <div class="title-box">           
            <div class="cfx"></div>
            <p>{{$t('accountCenter.onlineBankTitle1')}}</p>
          </div>
          <div class="account-data">
            <div class="bank-main">
              <p v-if="payType === 'szfucvs'||payType==='szfucvsfamilys'||payType==='szfucvshilife'||payType==='szfucvsibons'||payType==='szfucvsokmart'" class="title1">{{$t('accountCenter.onlineBankTitle2CSV')}}</p>
              <p v-else class="title1">{{$t('accountCenter.onlineBankTitle2')}}</p>
              <p class="title2">{{$t('accountCenter.onlineBankTitle3')}}<span>{{countdownTime}}</span>{{$t('accountCenter.onlineBankTitle4')}}</p>
              <div v-if="payType === 'TestPay'" class="pay-success-box">
                <button @click="testPaySuccess(2)">支付完成</button>
                <button @click="testPaySuccess(0)">支付失败</button>
              </div>
              <div class="bank-card-box" v-else>
                <div class="bank-card-data">
                  <p class="prompt" id="prompt">{{$t('accountCenter.copySucc')}}</p>
                  <div class="bank-card-list" :class="styleState">
                    <p>
                      <span v-if="payType === 'szfucvs'||payType==='szfucvsfamilys'||payType==='szfucvshilife'||payType==='szfucvsibons'||payType==='szfucvsokmart'" class="tlt">{{$t('accountCenter.remittanceAmountCSV')}}</span>
                      <span v-else class="tlt">{{$t('accountCenter.remittanceAmount')}}</span>
                      <span id="money">{{amount}}</span><span>{{paymentCurrency}}</span>
                    </p>
                    <button @click="copyMoney('money')">{{$t('accountCenter.copy')}}</button>
                  </div>
                  <div class="bank-card-list" :class="styleState">
                    <p>
                      <span v-if="payType === 'szfucvs'||payType==='szfucvsfamilys'||payType==='szfucvshilife'||payType==='szfucvsibons'||payType==='szfucvsokmart'" class="tlt">{{$t('accountCenter.collectionBankCSV')}}</span>
                      <span v-else class="tlt">{{$t('accountCenter.collectionBank')}}</span>
                      <span id="bankName">{{bankName}}</span>
                    </p>
                    <button @click="copyBankName('bankName')">{{$t('accountCenter.copy')}}</button>
                  </div>
                  <div v-if="payType === 'szfuatm'" class="bank-card-list" :class="styleState">
                    <p>               
                      <span class="tlt">{{$t('accountInfor.bindBankTitle3')}}：</span>
                      <span id="bankCode">{{bankCode}}</span>
                    </p>
                    <button @click="copyBankCode('bankCode')">{{$t('accountCenter.copy')}}</button>
                  </div>
                  <div class="bank-card-list" :class="styleState">
                    <p>
                      <span v-if="payType === 'szfucvs'||payType==='szfucvsfamilys'||payType==='szfucvshilife'||payType==='szfucvsibons'||payType==='szfucvsokmart'" class="tlt">{{$t('accountCenter.collectionAccountCSV')}}</span>
                      <span v-else class="tlt">{{$t('accountCenter.collectionAccount')}}</span>
                      <span id="accountNumber">{{bankCardNumber}}</span></p>
                    <button @click="copyAccountNumber('accountNumber')">{{$t('accountCenter.copy')}}</button>
                  </div>
                </div>
                <div class="bank-card-bottom">
                  <img src="@/assets/images/transaction/anqu-icon.png" alt="">
                  <p>{{$t('accountCenter.onlineBankTitle10')}}</p>
                </div>
              </div>
              <div class="transfer-prompt">
                <p class="p1">{{$t('accountCenter.onlineBankTitle5')}}</p>  
                <p v-if="payType === 'szfucvs'||payType==='szfucvsfamilys'||payType==='szfucvshilife'||payType==='szfucvsibons'||payType==='szfucvsokmart'" class="p2">{{$t('accountCenter.onlineBankTitle6CSV')}}</p>
                <p v-else class="p2">{{$t('accountCenter.onlineBankTitle6')}}</p>
                <p v-if="payType === 'szfucvs'||payType==='szfucvsfamilys'||payType==='szfucvshilife'||payType==='szfucvsibons'||payType==='szfucvsokmart'" class="p2">{{$t('accountCenter.onlineBankTitle7CSV')}}</p>
                <p v-else class="p2">{{$t('accountCenter.onlineBankTitle7')}}</p>
                <p v-if="payType !== 'szfucvs'" class="p2">{{$t('accountCenter.onlineBankTitle8')}}</p>
                <p v-if="payType === 'szfucvs'||payType==='szfucvsfamilys'||payType==='szfucvshilife'||payType==='szfucvsibons'||payType==='szfucvsokmart'" class="p2">{{$t('accountCenter.onlineBankTitle9CSV')}}</p>
                <p v-else class="p2">{{$t('accountCenter.onlineBankTitle9')}}</p>
              </div>
            </div> 

            <!-- 入金成功弹窗 -->
            <div class="reward-data-modal">
              <div class="mask" v-if="showModal" @click="confirmShow"></div>
              <div class="pop" v-if="showModal">
                <div class="data-box">
                  <img @click="confirmShow" class="close-btn" src="@/assets/images/transaction/close-icon.png" alt="">
                  <div class="reward-box succes-box">
                    <div class="reward-data">
                      <img class="cg-icon" :src="require('@/assets/images/transaction/cg-icon'+styleimg+'.png')" alt="">
                      <p class="title">{{$t('accountCenter.depositSucc')}}</p>
                      <div class="succes-data" :class="styleState1">
                        <div class="succes-list">
                          <p class="p1">{{$t('accountCenter.depositTite2')}}</p>
                          <p class="p2">{{amount}} {{paymentCurrency}}</p>
                        </div>
                        <div class="succes-list">
                          <p class="p1">{{$t('accountCenter.depositTime')}}</p>
                          <p class="p2">{{depositTime}}</p>
                        </div>
                        <button @click="confirmShow">{{$t('accountCenter.confirm')}}</button>
                      </div>
                    </div>               
                  </div>                     
                </div>     
              </div>          
            </div>                      
          </div>  
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import LeftMenu from '@/components/LeftMenu.vue';
import TransactionNav from '@/components/TransactionNav.vue';
import account from "@/api/account";
import profit from "@/util/profitsdk";

export default {
  name: "OnlineBank",
  components: {
    Header,
    LeftMenu,
    TransactionNav
  },
  data() {
    return {
      isClient: profit.isClient,
      loginState:2,
      styleState:'',
      styleState1:'',
      styleState2:'',
      styleimg:'',
      showModal:false,
      transId:'',
      payType:'',
      amount:'',
      paymentCurrency:'',
      bankName:'--',
      bankCode:'--',
      bankCardNumber:'--',
      countdownTime:'15:00',
      depositTime:'',
      timerCountdown:0,
      timerStatus:0,
    }
  },
  created() {
    this.transId = this.$route.query.transId;
    this.payType = this.$route.query.payType;
    this.amount = this.$route.query.amount;

    // 根据马甲包ui颜色改变页面ui颜色
    const appId = this.$route.query.appId;
      switch(appId) {
          case "com.exchange6.app":
          case "com.fnsadw.ios":
          case "com.cmtrading.app.droid":
          case "com.mobile.trade.finance.emns": 
          case "com.finance.etoo.invest":
          case "com.cmtrading.axbk.ios":    
              console.log("blue-submit-deposit");
              this.styleState = "blue-bank-card-list";
              this.styleState1 = "blue-succes-data";
              this.styleState2 = "blue-account-information";
              this.styleimg = "-blue"; 
              break;
          case "com.forex.market.investasikan":
          case "com.forex.app03.ios":
          case "com.get.money.investapp.gold":
              console.log("orange-submit-deposit");
              this.styleState = "orange-bank-card-list";
              this.styleState1 = "orange-succes-data";
              this.styleState2 = "orange-account-information";
              this.styleimg = "-orange"; 
              break;
          case "com.stock.market.cm.tw":
              console.log("green-submit-deposit");
              this.styleState = "green-bank-card-list";
              this.styleState1 = "green-succes-data";
              this.styleState2 = "green-account-information";
              this.styleimg = "-green"; 
              break;    
          default:
              this.styleState = " ";
              this.styleState1 = " ";
              this.styleState2 = " ";
              this.styleimg = "-";
      } 

    if (this.payType === 'TestPay'){

    }else if (this.payType === 'szfucvs'||this.payType==='szfucvsfamilys'||this.payType==='szfucvshilife'||this.payType==='szfucvsibons'||this.payType==='szfucvsokmart'){
      this.bankName = this.$route.query.cvsName;
      this.bankCardNumber = this.$route.query.cvsCode;
    }else {
      this.bankName = this.$route.query.bankName;
      this.bankCode = this.$route.query.bankCode;
      this.bankCardNumber = this.$route.query.bankCardNumber;
    }
    console.log('this.isClient='+this.isClient);

    var userInfo;
    account.findUserInfo()
        .then(res => {
          userInfo =  res.data.value;
          return account.getSupportCountry();
        }).then(res => {
          const contrys = res.data.value;
          const countyConfig = contrys.find(contry => contry.countryId === userInfo.countryId);
          this.paymentCurrency = countyConfig.paymentCurrency;
        })

    this.countdown(14);
    this.queryOrderStatus();
    const that = this;
    this.timerStatus = setInterval(()=> {
      if (that){
        that.queryOrderStatus();
      }
    },3000);
  },
  unmounted() {
    if (this.timerCountdown){
      clearInterval(this.timerCountdown);
      this.timerCountdown = 0;
    }
    if (this.timerStatus){
      clearInterval(this.timerStatus);
      this.timerStatus = 0;
    }
  },
  destroyed: function (){
    if (this.timerCountdown){
      clearInterval(this.timerCountdown);
      this.timerCountdown = 0;
      
    }
    if (this.timerStatus){
      clearInterval(this.timerStatus);
      this.timerStatus = 0;
    }
  },
  methods: {
    countdown(minute){
      const that = this;
      account.queryOrderByOrder({orderId:this.transId})
          .then(res => {
            if (res.data.success){
              const order = res.data.value;
              // console.log(order.createTime);
              //"2022-01-06 15:25:49"
              var dataTime = this.timestampToTime(order.createTime)
              var createTime = that.getDate(dataTime);
              // console.log(createTime);
              this.timerCountdown = setInterval(()=>{
                if (that) {
                  var now = new Date();
                  var dtime = now.getTime() - createTime.getTime();
                  // console.log(now,createTime,dtime);
                  if (dtime > minute * 60 * 1000) {
                    clearInterval(this.timerCountdown);
                    clearInterval(this.timerStatus);
                    this.timerCountdown = null;
                    this.timerStatus = null;
                    // alert('轉賬超時，請重試');
                    if (profit.isClient) {
                      profit.deposit({
                        success() {
                        },
                        fail() {
                        }
                      })
                    } else {
                      that.$router.push({
                        path: 'Deposit'
                      });
                    }
                  } else {
                    var sec = (minute * 60 * 1000 - dtime) / 1000;
                    var s = (sec % 60).toFixed();
                    that.countdownTime = (sec / 60).toFixed() + ":" + (s < 10 ? "0" + s : s);
                    // console.log(that.countdownTime);
                  }
                }
              },1000);
            }
          })
    },
     getDate(strDate) {
        var st = strDate;
        var a = st.split(" ");
        var b = a[0].split("-");
        var c = a[1].split(":");
        var date = new Date(b[0], b[1] - 1, b[2], c[0], c[1], c[2]);
        return date;
      },
// 支付成功确认关闭按钮
    confirmShow(){
      this.showModal = false
      if (profit.isClient){
        profit.deposit({
          success(){},
          fail(){}
        })
      }else {
        this.$router.push({
          path: 'Deposit'
        });
      }
    },

    //复制金额
    copyMoney($id){
      this.selectText($id);
      document.execCommand('copy');
    },
    //复制银行名称
    copyBankName($id){
      this.selectText($id);
      document.execCommand('copy');
    },
     //复制银行代码
    copyBankCode($id){
      this.selectText($id);
      document.execCommand('copy');
    },
    //复制银行账号
    copyAccountNumber($id){
      this.selectText($id);
      document.execCommand('copy');
    },

    //选中文本
    selectText(element) {
        var text = document.getElementById(element);
        //做下兼容
        if (document.body.createTextRange) {  //如果支持
            var range = document.body.createTextRange(); //获取range
            range.moveToElementText(text); //光标移上去
            range.select();  //选择
        } else if (window.getSelection) {
            var selection = window.getSelection(); //获取selection
            var range = document.createRange(); //创建range
            range.selectNodeContents(text);  //选择节点内容
            selection.removeAllRanges(); //移除所有range
            selection.addRange(range);  //添加range
            /*if(selection.setBaseAndExtent){
             selection.setBaseAndExtent(text, 0, text, 1);
             }*/
             document.getElementById('prompt').style.display='block';
            setTimeout("document.getElementById('prompt').style.display='none'",1000);               
        }
    },
    queryOrderStatus(){
      const that = this;
      // timerStatus = setInterval(()=>{
        account.queryOrderByOrder({orderId:that.transId})
            .then(res => {
              if (res.data.success){
                const order = res.data.value;
                if (order.status == 6){ //支付完成
                  clearInterval(this.timerCountdown);
                  clearInterval(this.timerStatus);
                  this.timerCountdown = 0;
                  this.timerStatus = 0;
                  that.depositTime = this.timestampToTime(order.updateTime);
                  that.showModal = true;
                }
              }
            })
      // },3000);
    },
    // 将时间戳转换为日期格式
    timestampToTime(timestamp) {
        var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-';
        var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
        var D = (date.getDate() < 10 ? '0'+ date.getDate(): date.getDate()) + ' ';
        var h = (date.getHours() < 10 ? '0'+ date.getHours(): date.getHours()) + ':';
        var m = (date.getMinutes() < 10 ? '0'+ date.getMinutes(): date.getMinutes()) + ':';
        var s = (date.getSeconds() < 10 ? '0'+ date.getSeconds(): date.getSeconds());
        return Y+M+D+h+m+s;
    },
    testPaySuccess(status){
      account.testpay({
        transId: this.transId,
        payType: this.payType,
        status: status
      }).then(res =>{
        clearInterval(this.timerCountdown);
        clearInterval(this.timerStatus);
        this.timerCountdown = 0;
        this.timerStatus = 0;
        if (status == 2 && res.data.success){
          this.depositTime = new Date();
          this.showModal = true;
        }else {
          // alert('支付失败！');
          if (profit.isClient){
            profit.deposit({
              success(){},
              fail(){}
            })
          }else {
            this.$router.push({
              path: 'Deposit'
            });
          }
        }
      })
    }
  },
};
</script>
<style>
@import url("../assets/css/public.css");
</style>
